<template>
  <div class="post__content">
    <div class="post__text">{{ post.content }}</div>
  </div>
</template>

<script>
export default {
  name: 'TextContent',
  props: {
    post: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
