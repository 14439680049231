<template>
  <div class="post__content">
    <div class="post__audio">
      <Player :src="post.attachmentPath" />
    </div>
  </div>
</template>

<script>
import Player from '@/components/AudioPlayer'

export default {
  name: 'AudioContent',
  components: {Player},
  props: {
    post: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
