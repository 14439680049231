<template>
  <div class="posts__wrapper">
    <div class="posts__header">
      <div class="posts__title">Regular Posts</div>
      <div class="posts__nav">
        <div
          class="posts__nav-link"
          :class="{'posts__nav-link--active': !filterParamsLocal.type}"
          @click="filterPost(null)"
        >
          All
        </div>
        <div
          class="posts__nav-link"
          :class="{'posts__nav-link--active': filterParamsLocal.type === 'post'}"
          @click="filterPost('post')"
        >
          General
        </div>
        <div
          class="posts__nav-link"
          :class="{'posts__nav-link--active': filterParamsLocal.type === 'question'}"
          @click="filterPost('question')"
        >
          Question
        </div>
        <div
          class="posts__nav-link"
          :class="{'posts__nav-link--active': filterParamsLocal.type === 'forSale'}"
          @click="filterPost('forSale')"
        >
          For Sale
        </div>
      </div>
    </div>

    <div v-if="isLoading" class="posts__empty">
      <Skeleton v-for="i of 6" :key="i" :height="'120px'" />
    </div>
    <div v-else class="posts__inner">
      <Post v-for="post in posts" :key="post.id" :post="post" @click.native="goPost(post)" />
    </div>

    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="infLoad"></infinite-loading>
  </div>
</template>

<script>
import Post from '../Post'
import {mapState, mapActions, mapMutations} from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import Skeleton from '@/components/Skeleton'

export default {
  name: 'RegularPosts',
  components: {Post, InfiniteLoading, Skeleton},
  data() {
    return {
      isLoading: false,
      filterPostType: null
    }
  },
  computed: {
    ...mapState({
      posts: s => s.community.posts.posts,
      pagination: s => s.community.posts.pagination,
      page: s => s.community.posts.page,
      filterParamsLocal: s => s.community.posts.filterParamsLocal,
      filterParamsSend: s => s.community.posts.filterParamsSend
    }),
    hasMoreResults() {
      return this.posts.length < this.pagination.total && this.page > 1
    }
  },
  async created() {
    this.isLoading = true
    if (!this.posts.length) await this.fetch()
    this.isLoading = false
  },
  methods: {
    ...mapActions({
      fetch: 'community/posts/fetch'
    }),
    ...mapMutations({
      reset: 'community/posts/reset',
      setFilterParamsLocal: 'community/posts/setFilterParamsLocal',
      setFilterParamsSend: 'community/posts/setFilterParamsSend'
    }),
    async filterPost(type) {
      const postsBlock = document.querySelectorAll('.posts__wrapper')[1]
      postsBlock.style.minHeight = postsBlock.clientHeight + 'px'
      this.setFilterParamsLocal({type})
      this.setFilterParamsSend({'filters[postType][]': type})
      this.isLoading = true
      this.reset()
      await this.fetch()
      this.isLoading = false
      postsBlock.style.minHeight = ''
    },
    async infLoad() {
      this.isLoading = true
      try {
        await this.fetch()
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    },
    goPost(post) {
      if (post.postType === 'forSale') this.$router.push({path: `/community/${post.id}`, query: {type: 'forSale'}})
      else if (post.postType === 'sold') this.$router.push({path: `/community/${post.id}`, query: {type: 'sold'}})
      else this.$router.push(`/community/${post.id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
