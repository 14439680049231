var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auction__wrapper" }, [
    _vm.post.postType === "forSale"
      ? _c("div", { staticClass: "auction__price" }, [
          _c("span", { staticClass: "auction__price-title" }, [
            _vm._v("Current bid ")
          ]),
          _vm.post.currentBid
            ? _c("span", { staticClass: "auction__price-number" }, [
                _vm._v("$ " + _vm._s(_vm.post.currentBid))
              ])
            : _c("span", { staticClass: "auction__price-number" }, [
                _vm._v("$ 0")
              ])
        ])
      : _vm._e(),
    _vm.post.postType === "sold" && _vm.post.soldFor
      ? _c("div", { staticClass: "auction__price is-orange" }, [
          _c("span", { staticClass: "auction__price-title" }, [
            _vm._v("Sold for ")
          ]),
          _c("span", { staticClass: "auction__price-number" }, [
            _vm._v("$ " + _vm._s(_vm.post.soldFor))
          ])
        ])
      : _vm.post.postType === "sold" && !_vm.post.soldFor
      ? _c("div", { staticClass: "auction__price is-orange" }, [
          _c("span", { staticClass: "auction__price-title" }, [
            _vm._v("Sold Personally ")
          ])
        ])
      : _vm._e(),
    !_vm.wasEdit && _vm.post.postType === "forSale"
      ? _c(
          "form",
          {
            staticClass: "auction__form",
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.placeBid($event)
              }
            }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.bid,
                  expression: "bid",
                  modifiers: { trim: true }
                },
                {
                  name: "mask",
                  rawName: "v-mask",
                  value: "##########",
                  expression: "'##########'"
                }
              ],
              staticClass: "field__input",
              attrs: { type: "text", placeholder: "Your bid price" },
              domProps: { value: _vm.bid },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.bid = $event.target.value.trim()
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _c(
              "button",
              { staticClass: "button button--fill", attrs: { type: "submit" } },
              [_vm._v("Place bid")]
            )
          ]
        )
      : _c("div", { staticClass: "auction__buttons" }, [
          (_vm.post.postType === "sold" && _vm.post.soldFor) ||
          (_vm.post.postType === "forSale" && _vm.post.currentBid)
            ? _c(
                "button",
                {
                  staticClass: "button button--fill",
                  on: { click: _vm.viewBids }
                },
                [_vm._v(" View Bids Activity ")]
              )
            : _vm._e(),
          _vm.post.postType !== "sold"
            ? _c(
                "button",
                {
                  staticClass: "button button--orange",
                  on: { click: _vm.sold }
                },
                [_vm._v("Mark as Sold")]
              )
            : _vm._e()
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }