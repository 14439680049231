<template>
  <div class="post__wrapper">
    <div
      class="post__header"
      :class="{
        'post__header--actions': wasEdit || isBishop
      }"
    >
      <div class="post__avatar">
        <span>{{ post.authorBusinessName[0] }}</span>
      </div>
      <div class="post__create">
        <div class="post__create-author">
          <span>{{ post.authorBusinessName }}</span>
          <span v-if="post.authorNickName"> ({{ post.authorNickName }})</span>
          <span v-if="post.authorFathersName">{{ post.authorFathersName }}’ </span>
          <span>{{ post.authorFirstName }}</span>
          <span> {{ post.authorMiddleInitial }}</span>
          <span> {{ post.authorLastName }}</span>
        </div>
        <div class="post__create-date">{{ moment(post.updatedAt).format('DD MMM HH:mm') }}</div>
      </div>
      <div
        class="post__type"
        :class="{
          'is-blue': post.postType === 'post',
          'is-green': post.postType === 'question',
          'is-red': post.postType === 'forSale',
          'is-orange': post.postType === 'sold'
        }"
      >
        <span v-if="post.postType === 'post'">General</span>
        <span v-else-if="post.postType === 'question'">Question</span>
        <span v-else-if="post.postType === 'forSale'">For Sale</span>
        <span v-else-if="post.postType === 'sold'">Sold</span>
      </div>
      <div class="post__date">{{ moment(post.updatedAt).format('DD MMM HH:mm') }}</div>
      <div class="post__actions" @click.stop>
        <el-dropdown v-if="wasEdit || isBishop" trigger="click" @command="action">
          <i class="ri-more-fill"></i>
          <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
            <el-dropdown-item v-if="contentType !== 'audio'" class="item--edit" command="Edit">
              <i class="ri-pencil-fill"></i> Edit
            </el-dropdown-item>
            <el-dropdown-item class="item--remove" command="Remove">
              <i class="ri-delete-bin-7-fill"></i> Remove
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="post__main">
      <TextContent v-if="!contentType" :post="post" />
      <ImageContent v-if="contentType === 'image'" :post="post" />
      <AudioContent v-if="contentType === 'audio'" :post="post" />
    </div>
    <div class="post__footer">
      <Auction v-if="post.postType === 'forSale' || post.postType === 'sold'" :post="post" @click.native.stop />
      <div class="post__footer-inner">
        <div class="post__footer-avatar">
          <span>{{ post.authorBusinessName[0] }}</span>
        </div>
        <div class="post__footer-create">
          <div class="post__footer-create-name">
            <span>{{ post.authorBusinessName }}</span>
            <span v-if="post.authorNickName"> ({{ post.authorNickName }})</span>
          </div>
          <div class="post__footer-create-fname">
            <span v-if="post.authorFathersName">{{ post.authorFathersName }}’ </span>
            <span>{{ post.authorFirstName }}</span>
            <span> {{ post.authorMiddleInitial }}</span>
            <span> {{ post.authorLastName }}</span>
          </div>
        </div>
        <div class="post__footer-avatar">
          <i class="ri-chat-3-fill"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextContent from './TextContent'
import ImageContent from './ImageContent'
import AudioContent from './AudioContent'
import Auction from './Auction'
import moment from 'moment'
import NewPost from '../NewPost'
import {mapState, mapActions, mapMutations} from 'vuex'

export default {
  name: 'RegularPost',
  components: {TextContent, ImageContent, AudioContent, Auction},
  props: {
    post: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      contentType: null,
      moment
    }
  },
  computed: {
    ...mapState({
      isBishop: s => s.auth.user.communityBishop,
      userID: s => s.auth.user.id
    }),
    wasEdit() {
      return this.post.authorID === this.userID
    }
  },
  created() {
    if (this.post.attachmentType) {
      const indexSymbol = this.post.attachmentType.indexOf('/')
      this.contentType = this.post.attachmentType.slice(0, indexSymbol)
    }
  },
  methods: {
    ...mapActions({
      delete: 'community/posts/delete',
      fetch: 'community/posts/fetch'
    }),
    ...mapMutations({
      reset: 'community/posts/reset'
    }),
    async action(action) {
      const mobile = document.documentElement.clientWidth <= 480
      let modalHeight = mobile ? {height: '100%'} : {height: 600}
      if (action === 'Edit') {
        this.$modal.display(
          NewPost,
          {
            type: 'text',
            post: this.post
          },
          {
            name: 'NewPostModal',
            transition: 'none',
            ...modalHeight,
            classes: 'modal modal-new-post'
          }
        )
      } else if (action === 'Remove') {
        await this.delete(this.post.id)
        this.reset()
        await this.fetch()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
