<template>
  <div class="post__content">
    <div class="post__text">{{ post.content }}</div>
    <img class="post__photo" :src="post.attachmentPath" />
  </div>
</template>

<script>
export default {
  name: 'ImageContent',
  props: {
    post: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
