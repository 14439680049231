<template>
  <div class="posts__wrapper">
    <div class="posts__title">Pinned Posts</div>

    <div v-if="isLoading" class="posts__empty">
      <Skeleton v-for="i of 3" :key="i" :height="'70px'" />
    </div>
    <div v-else class="posts__inner">
      <Post v-for="post in posts" :key="post.id" :post="post" />
    </div>
  </div>
</template>

<script>
import Post from '../PinnedPost'
import {mapState, mapActions} from 'vuex'
import Skeleton from '@/components/Skeleton'

export default {
  name: 'PinnedPosts',
  components: {Post, Skeleton},
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      posts: s => s.community.posts.pinnedPosts
    })
  },
  async created() {
    this.isLoading = true
    await this.fetch()
    this.isLoading = false
  },
  methods: {
    ...mapActions({
      fetch: 'community/posts/fetchPinnedPosts'
    })
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
