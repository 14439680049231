var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "post__wrapper" }, [
    _c(
      "div",
      {
        staticClass: "post__header",
        class: {
          "post__header--actions": _vm.wasEdit || _vm.isBishop
        }
      },
      [
        _c("div", { staticClass: "post__avatar" }, [
          _c("span", [_vm._v(_vm._s(_vm.post.authorBusinessName[0]))])
        ]),
        _c("div", { staticClass: "post__create" }, [
          _c("div", { staticClass: "post__create-author" }, [
            _c("span", [_vm._v(_vm._s(_vm.post.authorBusinessName))]),
            _vm.post.authorNickName
              ? _c("span", [
                  _vm._v(" (" + _vm._s(_vm.post.authorNickName) + ")")
                ])
              : _vm._e(),
            _vm.post.authorFathersName
              ? _c("span", [_vm._v(_vm._s(_vm.post.authorFathersName) + "’ ")])
              : _vm._e(),
            _c("span", [_vm._v(_vm._s(_vm.post.authorFirstName))]),
            _c("span", [_vm._v(" " + _vm._s(_vm.post.authorMiddleInitial))]),
            _c("span", [_vm._v(" " + _vm._s(_vm.post.authorLastName))])
          ]),
          _c("div", { staticClass: "post__create-date" }, [
            _vm._v(
              _vm._s(_vm.moment(_vm.post.updatedAt).format("DD MMM HH:mm"))
            )
          ])
        ]),
        _c(
          "div",
          {
            staticClass: "post__type",
            class: {
              "is-blue": _vm.post.postType === "post",
              "is-green": _vm.post.postType === "question",
              "is-red": _vm.post.postType === "forSale",
              "is-orange": _vm.post.postType === "sold"
            }
          },
          [
            _vm.post.postType === "post"
              ? _c("span", [_vm._v("General")])
              : _vm.post.postType === "question"
              ? _c("span", [_vm._v("Question")])
              : _vm.post.postType === "forSale"
              ? _c("span", [_vm._v("For Sale")])
              : _vm.post.postType === "sold"
              ? _c("span", [_vm._v("Sold")])
              : _vm._e()
          ]
        ),
        _c("div", { staticClass: "post__date" }, [
          _vm._v(_vm._s(_vm.moment(_vm.post.updatedAt).format("DD MMM HH:mm")))
        ]),
        _c(
          "div",
          {
            staticClass: "post__actions",
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _vm.wasEdit || _vm.isBishop
              ? _c(
                  "el-dropdown",
                  { attrs: { trigger: "click" }, on: { command: _vm.action } },
                  [
                    _c("i", { staticClass: "ri-more-fill" }),
                    _c(
                      "el-dropdown-menu",
                      {
                        staticClass: "el-popper-custom el-popper--actions",
                        attrs: { slot: "dropdown" },
                        slot: "dropdown"
                      },
                      [
                        _vm.contentType !== "audio"
                          ? _c(
                              "el-dropdown-item",
                              {
                                staticClass: "item--edit",
                                attrs: { command: "Edit" }
                              },
                              [
                                _c("i", { staticClass: "ri-pencil-fill" }),
                                _vm._v(" Edit ")
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "el-dropdown-item",
                          {
                            staticClass: "item--remove",
                            attrs: { command: "Remove" }
                          },
                          [
                            _c("i", { staticClass: "ri-delete-bin-7-fill" }),
                            _vm._v(" Remove ")
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ]
    ),
    _c(
      "div",
      { staticClass: "post__main" },
      [
        !_vm.contentType
          ? _c("TextContent", { attrs: { post: _vm.post } })
          : _vm._e(),
        _vm.contentType === "image"
          ? _c("ImageContent", { attrs: { post: _vm.post } })
          : _vm._e(),
        _vm.contentType === "audio"
          ? _c("AudioContent", { attrs: { post: _vm.post } })
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "post__footer" },
      [
        _vm.post.postType === "forSale" || _vm.post.postType === "sold"
          ? _c("Auction", {
              attrs: { post: _vm.post },
              nativeOn: {
                click: function($event) {
                  $event.stopPropagation()
                }
              }
            })
          : _vm._e(),
        _c("div", { staticClass: "post__footer-inner" }, [
          _c("div", { staticClass: "post__footer-avatar" }, [
            _c("span", [_vm._v(_vm._s(_vm.post.authorBusinessName[0]))])
          ]),
          _c("div", { staticClass: "post__footer-create" }, [
            _c("div", { staticClass: "post__footer-create-name" }, [
              _c("span", [_vm._v(_vm._s(_vm.post.authorBusinessName))]),
              _vm.post.authorNickName
                ? _c("span", [
                    _vm._v(" (" + _vm._s(_vm.post.authorNickName) + ")")
                  ])
                : _vm._e()
            ]),
            _c("div", { staticClass: "post__footer-create-fname" }, [
              _vm.post.authorFathersName
                ? _c("span", [
                    _vm._v(_vm._s(_vm.post.authorFathersName) + "’ ")
                  ])
                : _vm._e(),
              _c("span", [_vm._v(_vm._s(_vm.post.authorFirstName))]),
              _c("span", [_vm._v(" " + _vm._s(_vm.post.authorMiddleInitial))]),
              _c("span", [_vm._v(" " + _vm._s(_vm.post.authorLastName))])
            ])
          ]),
          _vm._m(0)
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "post__footer-avatar" }, [
      _c("i", { staticClass: "ri-chat-3-fill" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }