var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "post__wrapper",
      class: {
        "is-blue": _vm.post.pinType === "default",
        "is-red": _vm.post.pinType === "warning",
        "is-orange": _vm.post.pinType === "important"
      }
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "post__info" }, [
        _c("div", { staticClass: "post__date" }, [
          _vm._v(_vm._s(_vm.moment(_vm.post.date).format("DD MMM HH:mm")))
        ]),
        _c("div", { staticClass: "post__title" }, [
          _vm._v(_vm._s(_vm.post.content))
        ])
      ]),
      _c(
        "div",
        { staticClass: "post__actions" },
        [
          _vm.isBishop
            ? _c(
                "el-dropdown",
                { attrs: { trigger: "click" }, on: { command: _vm.action } },
                [
                  _c("i", { staticClass: "ri-more-fill" }),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "el-popper-custom el-popper--actions",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown"
                    },
                    [
                      _vm.isBishop
                        ? _c(
                            "el-dropdown-item",
                            {
                              staticClass: "item--edit",
                              attrs: { command: "Edit" }
                            },
                            [
                              _c("i", { staticClass: "ri-pencil-fill" }),
                              _vm._v(" Edit ")
                            ]
                          )
                        : _vm._e(),
                      _vm.isBishop
                        ? _c(
                            "el-dropdown-item",
                            {
                              staticClass: "item--remove",
                              attrs: { command: "Remove" }
                            },
                            [
                              _c("i", { staticClass: "ri-delete-bin-7-fill" }),
                              _vm._v(" Remove ")
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "post__icon" }, [
      _c("i", { staticClass: "ri-pushpin-fill" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }