var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "posts__wrapper" },
    [
      _c("div", { staticClass: "posts__header" }, [
        _c("div", { staticClass: "posts__title" }, [_vm._v("Regular Posts")]),
        _c("div", { staticClass: "posts__nav" }, [
          _c(
            "div",
            {
              staticClass: "posts__nav-link",
              class: { "posts__nav-link--active": !_vm.filterParamsLocal.type },
              on: {
                click: function($event) {
                  return _vm.filterPost(null)
                }
              }
            },
            [_vm._v(" All ")]
          ),
          _c(
            "div",
            {
              staticClass: "posts__nav-link",
              class: {
                "posts__nav-link--active": _vm.filterParamsLocal.type === "post"
              },
              on: {
                click: function($event) {
                  return _vm.filterPost("post")
                }
              }
            },
            [_vm._v(" General ")]
          ),
          _c(
            "div",
            {
              staticClass: "posts__nav-link",
              class: {
                "posts__nav-link--active":
                  _vm.filterParamsLocal.type === "question"
              },
              on: {
                click: function($event) {
                  return _vm.filterPost("question")
                }
              }
            },
            [_vm._v(" Question ")]
          ),
          _c(
            "div",
            {
              staticClass: "posts__nav-link",
              class: {
                "posts__nav-link--active":
                  _vm.filterParamsLocal.type === "forSale"
              },
              on: {
                click: function($event) {
                  return _vm.filterPost("forSale")
                }
              }
            },
            [_vm._v(" For Sale ")]
          )
        ])
      ]),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "posts__empty" },
            _vm._l(6, function(i) {
              return _c("Skeleton", { key: i, attrs: { height: "120px" } })
            }),
            1
          )
        : _c(
            "div",
            { staticClass: "posts__inner" },
            _vm._l(_vm.posts, function(post) {
              return _c("Post", {
                key: post.id,
                attrs: { post: post },
                nativeOn: {
                  click: function($event) {
                    return _vm.goPost(post)
                  }
                }
              })
            }),
            1
          ),
      !_vm.isLoading && _vm.hasMoreResults
        ? _c("infinite-loading", { on: { infinite: _vm.infLoad } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }