var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "post__content" }, [
    _c("div", { staticClass: "post__text" }, [
      _vm._v(_vm._s(_vm.post.content))
    ]),
    _c("img", {
      staticClass: "post__photo",
      attrs: { src: _vm.post.attachmentPath }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }