var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-wrapper" }, [
    _c("div", { staticClass: "page-header" }),
    _c(
      "div",
      { staticClass: "page-inner" },
      [
        _c(
          "el-dropdown",
          {
            attrs: { trigger: "click", placement: "bottom" },
            on: { command: _vm.action }
          },
          [
            _c(
              "button",
              { staticClass: "button button--fill button--rounded" },
              [
                _c("i", { staticClass: "ri-add-circle-fill" }),
                _vm._v(" New Post ")
              ]
            ),
            _c(
              "el-dropdown-menu",
              {
                staticClass: "el-popper-custom el-popper--add-post",
                attrs: { slot: "dropdown" },
                slot: "dropdown"
              },
              [
                _c(
                  "el-dropdown-item",
                  {
                    staticClass:
                      "button button--fill button--fill--grey button--rounded",
                    attrs: { command: "text" }
                  },
                  [
                    _c("i", { staticClass: "ri-image-fill" }),
                    _vm._v(" Text/Image ")
                  ]
                ),
                _c(
                  "el-dropdown-item",
                  {
                    staticClass:
                      "button button--fill button--fill--grey button--rounded",
                    attrs: { command: "audio" }
                  },
                  [_c("i", { staticClass: "ri-mic-fill" }), _vm._v(" Audio ")]
                )
              ],
              1
            )
          ],
          1
        ),
        _c("PinnedPosts"),
        _c("RegularPosts")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }