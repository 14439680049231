<template>
  <div class="page-wrapper">
    <div class="page-header"></div>
    <div class="page-inner">
      <el-dropdown trigger="click" placement="bottom" @command="action">
        <button class="button button--fill button--rounded">
          <i class="ri-add-circle-fill"></i>
          New Post
        </button>
        <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--add-post">
          <el-dropdown-item class="button button--fill button--fill--grey button--rounded" command="text">
            <i class="ri-image-fill"></i>
            Text/Image
          </el-dropdown-item>
          <el-dropdown-item class="button button--fill button--fill--grey button--rounded" command="audio">
            <i class="ri-mic-fill"></i>
            Audio
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <PinnedPosts />
      <RegularPosts />
    </div>
  </div>
</template>

<script>
import PinnedPosts from './PinnedPosts'
import RegularPosts from './RegularPosts'
import NewPost from './NewPost'

export default {
  name: 'Community',
  components: {PinnedPosts, RegularPosts},
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    action(type) {
      // if (type == 'text') {
      const mobile = document.documentElement.clientWidth <= 480
      let modalHeight = mobile ? {height: '100%'} : {height: 600}
      this.$modal.display(
        NewPost,
        {
          type
        },
        {
          name: 'NewPostModal',
          transition: 'none',
          ...modalHeight,
          classes: 'modal modal-new-post'
        }
      )
    }
    // }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
