var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "posts__wrapper" }, [
    _c("div", { staticClass: "posts__title" }, [_vm._v("Pinned Posts")]),
    _vm.isLoading
      ? _c(
          "div",
          { staticClass: "posts__empty" },
          _vm._l(3, function(i) {
            return _c("Skeleton", { key: i, attrs: { height: "70px" } })
          }),
          1
        )
      : _c(
          "div",
          { staticClass: "posts__inner" },
          _vm._l(_vm.posts, function(post) {
            return _c("Post", { key: post.id, attrs: { post: post } })
          }),
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }