<template>
  <div
    class="post__wrapper"
    :class="{
      'is-blue': post.pinType === 'default',
      'is-red': post.pinType === 'warning',
      'is-orange': post.pinType === 'important'
    }"
  >
    <div class="post__icon">
      <i class="ri-pushpin-fill"></i>
    </div>
    <div class="post__info">
      <div class="post__date">{{ moment(post.date).format('DD MMM HH:mm') }}</div>
      <div class="post__title">{{ post.content }}</div>
    </div>
    <div class="post__actions">
      <el-dropdown v-if="isBishop" trigger="click" @command="action">
        <i class="ri-more-fill"></i>
        <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
          <el-dropdown-item v-if="isBishop" class="item--edit" command="Edit">
            <i class="ri-pencil-fill"></i> Edit
          </el-dropdown-item>
          <!-- <el-dropdown-item v-if="isBishop" class="item--edit" command="Unpin">
            <i class="ri-pushpin-fill"></i> Unpin
          </el-dropdown-item> -->
          <el-dropdown-item v-if="isBishop" class="item--remove" command="Remove">
            <i class="ri-delete-bin-7-fill"></i> Remove
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import NewPost from '../NewPost'
import moment from 'moment'
import {mapState, mapActions, mapMutations} from 'vuex'

export default {
  name: 'PinnedPost',
  props: {
    post: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      moment
    }
  },
  computed: {
    ...mapState({
      isBishop: s => s.auth.user.communityBishop,
      userID: s => s.auth.user.id
    }),
    wasEdit() {
      return this.post.authorID === this.userID
    }
  },
  methods: {
    ...mapActions({
      delete: 'community/posts/delete',
      fetch: 'community/posts/fetch',
      fetchPinnedPosts: 'community/posts/fetchPinnedPosts'
    }),
    ...mapMutations({
      reset: 'community/posts/reset',
      resetPinnedPosts: 'community/posts/resetPinnedPosts'
    }),
    async action(action) {
      const mobile = document.documentElement.clientWidth <= 480
      let modalHeight = mobile ? {height: '100%'} : {height: 600}
      if (action === 'Edit') {
        this.$modal.display(
          NewPost,
          {
            type: 'text',
            post: this.post
          },
          {
            name: 'NewPostModal',
            transition: 'none',
            ...modalHeight,
            classes: 'modal modal-new-post'
          }
        )
      } else if (action === 'Remove') {
        await this.delete(this.post.id)
        this.resetPinnedPosts()
        await this.fetchPinnedPosts()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
