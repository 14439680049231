<template>
  <div class="auction__wrapper">
    <div v-if="post.postType === 'forSale'" class="auction__price">
      <span class="auction__price-title">Current bid </span>
      <span v-if="post.currentBid" class="auction__price-number">$ {{ post.currentBid }}</span>
      <span v-else class="auction__price-number">$ 0</span>
    </div>
    <div v-if="post.postType === 'sold' && post.soldFor" class="auction__price is-orange">
      <span class="auction__price-title">Sold for </span>
      <span class="auction__price-number">$ {{ post.soldFor }}</span>
    </div>
    <div v-else-if="post.postType === 'sold' && !post.soldFor" class="auction__price is-orange">
      <span class="auction__price-title">Sold Personally </span>
    </div>
    <form v-if="!wasEdit && post.postType === 'forSale'" class="auction__form" @submit.prevent="placeBid">
      <input v-model.trim="bid" v-mask="'##########'" type="text" class="field__input" placeholder="Your bid price" />
      <button class="button button--fill" type="submit">Place bid</button>
    </form>
    <div v-else class="auction__buttons">
      <button
        v-if="(post.postType === 'sold' && post.soldFor) || (post.postType === 'forSale' && post.currentBid)"
        class="button button--fill"
        @click="viewBids"
      >
        View Bids Activity
      </button>
      <button v-if="post.postType !== 'sold'" class="button button--orange" @click="sold">Mark as Sold</button>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import moment from 'moment'
import Popup from '../../AuctionPopup'

export default {
  name: 'Auction',
  props: {
    post: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      moment,
      bid: null
    }
  },
  computed: {
    ...mapState({
      userID: s => s.auth.user.id
    }),
    wasEdit() {
      return this.post.authorID === this.userID
    }
  },
  methods: {
    ...mapActions({
      fetch: 'community/auction/fetchCurrentBid',
      makeBid: 'community/auction/makeBid',
      markAsSold: 'community/posts/markAsSold'
    }),
    ...mapMutations({
      changeBid: 'community/posts/changeBid'
    }),
    async placeBid() {
      if (this.isLoading) return
      if (this.bid <= this.post.currentBid) return
      this.isLoading = true
      await this.makeBid({id: this.post.id, bid: this.bid})
      this.bid = null
      this.post.currentBid = await this.fetch(this.post.id)
      this.isLoading = false
      this.$notify({
        message: 'Your bid has been placed successfully!',
        position: 'bottom-right',
        iconClass: 'ri-checkbox-circle-fill',
        customClass: 'el-notification-bid',
        showClose: false
      })
    },
    viewBids() {
      const mobile = document.documentElement.clientWidth <= 480
      let modalHeight = mobile ? {height: '100%'} : {height: 'auto', maxHeight: 600}
      this.$modal.display(
        Popup,
        {post: this.post},
        {
          name: 'AuctionPopup',
          transition: 'none',
          overlayTransition: 'none',
          ...modalHeight,
          width: '800px',
          classes: 'modal modal-auction'
        }
      )
    },
    async sold() {
      await this.markAsSold(this.post.id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
